import React from "react";
import { Link } from "react-router-dom";
import "./Results.styles.css";
import logo from "../assets/logo_heyway.png";
import smile from "../assets/smile.svg";
import sad from "../assets/sad.svg";

const Results = ({ correctCounter, wrongCounter, setGoingBack, resetAll }) => {
  return (
    <div className="animate__animated animate__fadeInRight">
      <div className="header-container">
        <Link to="/">
          <img src={logo} alt={"Logo"} id="heyway-logo" />
        </Link>
        <Link
          to="/"
          className="resetBtn resetBtnResults"
          onClick={() => (window.location.href = "/")}
        >
          Reset
        </Link>
      </div>
      {(correctCounter / 25) * 100 > 79 ? (
        <div>
          <h2>Sådan du bestod!</h2>
          <img src={smile} className="smile-emoji" alt="smile emoji" />
          <div className="cor-wro-container cor-wro-container-results">
            <h3>
              <span className="correctSpan">{correctCounter}</span> korrekt -{" "}
              {Math.trunc((correctCounter / 25) * 100)} %
            </h3>
            <h3>
              <span className="wrongSpan">{wrongCounter}</span> forkert
            </h3>
          </div>
        </div>
      ) : (
        <div>
          <h2>Du bestod desværre ikke!</h2>
          <img src={sad} className="sad-emoji" alt="sad emoji" />
          <div className="cor-wro-container cor-wro-container-results">
            <h3>
              <span className="correctSpan">{correctCounter}</span> korrekt -{" "}
              {Math.trunc((correctCounter / 25) * 100)} %
            </h3>
            <h3>
              <span className="wrongSpan">{wrongCounter}</span> forkert
            </h3>
          </div>
        </div>
      )}

      <div className="bottomContainer">
        <div className="resultBtns">
          <Link
            onClick={() => setGoingBack(true)}
            to={"/"}
            className="resultBottomBtns"
          >
            Tilbage
          </Link>
        </div>
        <div className="resultBtns">
          <Link
            onClick={() => {
              resetAll();
            }}
            to={"/"}
            className="resultBottomBtns"
          >
            Ny test
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Results;
