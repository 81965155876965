import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "animate.css";
import "./Container.styles.css";
import logo from "../assets/logo_heyway.png";
import correctCheck from "../assets/correct_check.svg";
import wrongCheck from "../assets/x.svg";

const Container = ({
  array,
  setArray,
  correctCounter,
  setCorrectCounter,
  wrongCounter,
  setWrongCounter,
  finishRef,
  goingBack,
  resetAll,
  fadeOut,
  setFadeOut,
}) => {
  //state that holds current id of page
  const [id, setId] = useState(0);

  const [seeResultDis, setSeeResultDis] = useState("none");

  const [finishDisabled, setFinishDisabled] = useState(false);

  //state that control stayling of correct wrong btns, when finished is clicked
  const [correctWrongStyle, setCorrectWrongStyle] = useState("none");
  // const [counterStyle, setCounterStyle] = useState(0);

  //next & back button disabled state
  const [nextDisabled, setNextDisabled] = useState(false);
  const [backDisabled, setBackDisabled] = useState(false);

  const history = useHistory();

  //funtion that get trigered when any radio is clicked, updates the main array with the new radio value.
  const handleRadioChange = (e, id) => {
    let newArr = [...array];
    const name = e.target.name;
    const result = newArr[id].radios.filter((item) => item.id === name);
    result[0].value = e.target.value;
    setArray(newArr);
  };

  //handle click next and back buttons
  const handleNextClick = () => {
    if (array.length - 1 > id) {
      setId(id + 1);
      setBackDisabled(false);

      if (array.length - 2 === id) {
        setNextDisabled(true);
      }
    }
  };
  const handleBackClick = () => {
    if (id > 0) {
      setId(id - 1);
      setNextDisabled(false);
      if (id === 1) {
        setBackDisabled(true);
      }
    }
  };

  //handle finish button click
  const handleFinishClick = (e) => {
    setCorrectWrongStyle("flex");
    // setCounterStyle(1);
    setId(0);
    if (e) {
      e.target.disabled = true;
    }
    setNextDisabled(false);
    setBackDisabled(true);
    setSeeResultDis("block");
    setFinishDisabled(true);
  };

  //handle correct/wrong answer
  const count = (num) => {
    const newArr = array;
    newArr[id].result = num;
    setArray(newArr);
  };
  const handleCorrectClick = () => {
    if (array[id].result !== 1) {
      if (array[id].result === 0) {
        setWrongCounter(wrongCounter - 1);
      }
      count(1);
      setCorrectCounter(correctCounter + 1);
    }
    setTimeout(() => {
      handleNextClick();
    }, 300);
  };
  const handleWrongClick = () => {
    if (array[id].result !== 0) {
      if (array[id].result === 1) {
        setCorrectCounter(correctCounter - 1);
      }
      count(0);
      setWrongCounter(wrongCounter + 1);
    }

    setTimeout(() => {
      handleNextClick();
    }, 300);
  };
  //prompt page refresh on reset button click
  const refreshPage = () => {
    window.location.reload();
  };

  const checkResult = (item) => {
    if (item.result === 1) {
      return (
        <img
          className="image-correct-check image-checks "
          src={correctCheck}
          alt="correct check"
        ></img>
      );
    } else if (item.result === 0) {
      return (
        <img
          className="image-wrong-check image-checks "
          src={wrongCheck}
          alt="wronge check"
        ></img>
      );
    } else {
      return <div className="image-checks"></div>;
    }
  };

  const handleResultClick = (e) => {
    setFadeOut("animate__animated animate__fadeOutLeft");
    setTimeout(() => {
      history.push("/results", { from: "/" });
      setFadeOut(undefined);
    }, 400);
    console.log(e.target.className);
  };

  //useEffect to handle when user click back from results
  useEffect(() => {
    if (goingBack && finishRef.current) {
      handleFinishClick();
      finishRef.current.disabled = true;
    }
  }, [goingBack, finishRef]);

  return (
    <div className={fadeOut}>
      <div className="header-container">
        <img src={logo} alt={"Logo"} id="heyway-logo" />
        <button onClick={refreshPage} className="resetBtn">
          Reset
        </button>
      </div>
      <div>
        <div>
          <button
            className="finishTestBtn animate__animated  animate__fadeInDown"
            ref={finishRef}
            onClick={(e) => handleFinishClick(e)}
            disabled={finishDisabled}
          >
            Afslut test
          </button>
          <div className="resultDiv">
            <button
              className="resultBtn  animate__animated  animate__fadeIn"
              style={{ display: seeResultDis }}
              onClick={(e) => handleResultClick(e)}
            >
              Se resultat
            </button>
          </div>
        </div>
      </div>
      {array.map((item, index) => {
        if (item.id === id) {
          return (
            <div key={item.id}>
              <div className="imageDiv">
                <h2>{item.name}</h2>
                {checkResult(item)}
              </div>
              <div className="grid-container">
                <label></label>
                <label>Ja</label>
                <label>Nej</label>

                <label className="label-number">1 -</label>
                <input
                  type="radio"
                  name="radio-1"
                  value="Yes"
                  id="0"
                  checked={item.radios[0].value === "Yes"}
                  onChange={(e) => handleRadioChange(e, id)}
                ></input>
                <input
                  type="radio"
                  name="radio-1"
                  value="No"
                  id="0"
                  checked={item.radios[0].value === "No"}
                  onChange={(e) => handleRadioChange(e, id)}
                ></input>

                <label className="label-number">2 -</label>
                <input
                  type="radio"
                  name="radio-2"
                  value="Yes"
                  id="0"
                  checked={item.radios[1].value === "Yes"}
                  onChange={(e) => handleRadioChange(e, id)}
                ></input>
                <input
                  type="radio"
                  name="radio-2"
                  value="No"
                  id="0"
                  checked={item.radios[1].value === "No"}
                  onChange={(e) => handleRadioChange(e, id)}
                ></input>

                <label className="label-number">3 -</label>
                <input
                  type="radio"
                  name="radio-3"
                  value="Yes"
                  id="0"
                  checked={item.radios[2].value === "Yes"}
                  onChange={(e) => handleRadioChange(e, id)}
                ></input>
                <input
                  type="radio"
                  name="radio-3"
                  value="No"
                  id="0"
                  checked={item.radios[2].value === "No"}
                  onChange={(e) => handleRadioChange(e, id)}
                ></input>

                <label className="label-number">4 -</label>
                <input
                  type="radio"
                  name="radio-4"
                  value="Yes"
                  id="0"
                  checked={item.radios[3].value === "Yes"}
                  onChange={(e) => handleRadioChange(e, id)}
                ></input>
                <input
                  type="radio"
                  name="radio-4"
                  value="No"
                  id="0"
                  checked={item.radios[3].value === "No"}
                  onChange={(e) => handleRadioChange(e, id)}
                ></input>
              </div>
            </div>
          );
        }
        return <div key={index}></div>;
      })}
      <div className="bottomButtons">
        <div className="check-mark-container">
          <div style={{ display: correctWrongStyle }}>
            <img
              className="correctBtns wrong-check animate__animated animate__fadeInLeft"
              src={wrongCheck}
              onClick={handleWrongClick}
              alt="correct button"
            ></img>
            <div className="counter animate__animated  animate__fadeInDown">
              <span className="wrongSpan"> {wrongCounter}</span>
              <b> - </b>
              <span className="correctSpan">{correctCounter}</span>
            </div>
            <div
              className="individual-check-container"
              onClick={handleCorrectClick}
            >
              <img
                className="correctBtns correct-check animate__animated animate__fadeInRight"
                src={correctCheck}
                alt="wrong button"
              ></img>
            </div>
          </div>
        </div>
        <div className="next-back-btns">
          <button
            disabled={backDisabled}
            onClick={handleBackClick}
            className="animate__animated animate__fadeInLeft"
          >
            Forrige
          </button>
          <button
            disabled={nextDisabled}
            onClick={handleNextClick}
            className="animate__animated animate__fadeInRight"
          >
            Næste
          </button>
        </div>
      </div>
    </div>
  );
};

export default Container;
