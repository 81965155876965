import React, { useState, useRef } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Container from "./Components/Container/Container.js";
import Results from "./Components/Results/Results.component";

function App() {
  //array saved in state, contains all info needed to dispay the right page.
  const [array, setArray] = useState([
    {
      id: 0,
      name: "Billede 1",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 1,
      name: "Billede 2",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 2,
      name: "Billede 3",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 3,
      name: "Billede 4",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 4,
      name: "Billede 5",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 5,
      name: "Billede 6",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 6,
      name: "Billede 7",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 7,
      name: "Billede 8",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 8,
      name: "Billede 9",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 9,
      name: "Billede 10",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 10,
      name: "Billede 11",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 11,
      name: "Billede 12",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 12,
      name: "Billede 13",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 13,
      name: "Billede 14",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 14,
      name: "Billede 15",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 15,
      name: "Billede 16",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 16,
      name: "Billede 17",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 17,
      name: "Billede 18",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 18,
      name: "Billede 19",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 19,
      name: "Billede 20",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 20,
      name: "Billede 21",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 21,
      name: "Billede 22",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 22,
      name: "Billede 23",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 23,
      name: "Billede 24",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
    {
      id: 24,
      name: "Billede 25",
      radios: [
        { id: "radio-1", value: undefined },
        { id: "radio-2", value: undefined },
        { id: "radio-3", value: undefined },
        { id: "radio-4", value: undefined },
      ],
      result: undefined,
    },
  ]);

  const [correctCounter, setCorrectCounter] = useState(0);
  const [wrongCounter, setWrongCounter] = useState(0);

  //useState and ref to handle when user clicks back
  const [goingBack, setGoingBack] = useState(false);
  const finishRef = useRef(null);

  //state to olde value of animation on exit
  const [fadeOut, setFadeOut] = useState(null);

  // when reset of new test is clicked
  const resetAll = () => {
    array.forEach((item) => {
      item.radios.forEach((element) => {
        if (element.value !== undefined) {
          element.value = undefined;
        }
      });
      if (item.result !== undefined) {
        item.result = undefined;
      }
    });
  };
  // || item.result != undefined)

  return (
    <Router>
      <div className="App">
        <Route exact path="/">
          <header className="App-header">
            <Container
              array={array}
              setArray={setArray}
              correctCounter={correctCounter}
              setCorrectCounter={setCorrectCounter}
              wrongCounter={wrongCounter}
              setWrongCounter={setWrongCounter}
              finishRef={finishRef}
              goingBack={goingBack}
              setGoingBack={setGoingBack}
              resetAll={resetAll}
              fadeOut={fadeOut}
              setFadeOut={setFadeOut}
            />
          </header>
        </Route>
        <Switch>
          <Route exact path="/results">
            <Results
              correctCounter={correctCounter}
              wrongCounter={wrongCounter}
              setGoingBack={setGoingBack}
              resetAll={resetAll}
            />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
